<template>
	<Dialog :dialog="claimDialog" :dialog-width="900" :persistent="true">
		<template #title
			>Status Remark
			<v-chip
				class="ml-2 mr-2 text-capitalize"
				:color="getStatusColor(status_remark.drop_status)"
				small
				label
				v-if="status_remark && status_remark.drop_status"
				>{{ status_remark.drop_status }}
			</v-chip>
			<v-chip
				class="ml-2 mr-2 text-capitalize"
				outlined
				color="red white--text"
				small
				label
				v-if="claimSingle && claimSingle.category"
				>{{ claimSingle.category.replace("-", " ") }}
			</v-chip>
			<v-chip class="mb-1 mr-1" small>
				<v-avatar left small>
					<img v-if="claimSingle.profile_img" :src="claimSingle.profile_img" />
					<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
				</v-avatar>
				<span v-if="claimSingle && claimSingle.user_name">{{ claimSingle.user_name }}</span>
			</v-chip>
		</template>
		<template #body>
			<v-form
				ref="claimApprovalForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="mb-3">
					<v-col lg="3" cols="12" class="my-auto py-0">
						<Label for="" class="fw-500 primary--text"> Summary:</Label>
					</v-col>
					<v-col lg="9" class="my-auto py-0">
						<span
							class="fw-600 font-size-18"
							v-if="summaryData && summaryData[0] && summaryData[0].category"
							>{{ summaryData[0].category }}</span
						>
						<span>(Category)</span>
					</v-col>
					<!--<v-col lg="2" class="my-auto py-0"><label class="input-label">Claimed</label></v-col>
                    <v-col lg="2" class="my-auto py-0"><label class="input-label">Balance</label></v-col>
                    <v-col lg="4" class="my-auto py-0"><label class="input-label">Category</label></v-col> -->
				</v-row>
				<v-row class="mb-3">
					<v-col lg="3" cols="12" class="my-auto py-0">
						<label class="input-label"></label>
					</v-col>
					<v-col lg="2" class="my-auto py-0">
						<div for="" class="fw-500 mb-2">Total</div>
						<v-chip color="green" label outlined small
							><span v-if="summaryData && summaryData[0] && summaryData[0].allocated_amount">{{
								formatMoney(summaryData[0].allocated_amount)
							}}</span>
							<span v-else> $ 0.00</span></v-chip
						></v-col
					>
					<v-col lg="2" class="my-auto py-0">
						<div for="" class="fw-500 mb-2">Claimed</div>
						<v-chip color="orange" label outlined small
							><span v-if="summaryData && summaryData[0] && summaryData[0].taken">{{
								formatMoney(summaryData[0].taken)
							}}</span>
							<span v-else> $ 0.00</span></v-chip
						></v-col
					>
					<v-col lg="2" class="my-auto py-0">
						<div for="" class="fw-500 mb-2">Balance</div>
						<v-chip color="red" label outlined small>
							<span v-if="summaryData && summaryData[0] && summaryData[0].balance">
								{{ formatMoney(summaryData[0].balance) }}</span
							><span v-else> $ 0.00</span></v-chip
						></v-col
					>
				</v-row>
				<v-row class="mb-3">
					<v-col lg="3" cols="12" class="">
						<Label for="" class="fw-500 d-flex"> Requested Amount:</Label>
					</v-col>
					<v-col md="9" class="py-0">
						<span class="green--text">{{ formatMoney(requestedAmount) }}</span>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col lg="3" cols="12" class="">
						<Label for="" class="fw-500 d-flex"> Approved Amount:</Label>
					</v-col>
					<v-col md="9" class="py-0">
						<v-text-field
							hide-details
							type="number"
							outlined
							placeholder="Amount"
							v-model.number="status_remark.amount"
							v-on:keypress="limitDecimal($event, status_remark.amount)"
							v-on:paste="limitDecimal($event, status_remark.amount)"
							:disabled="status_remark.drop_status == 'Rejected'"
						>
						</v-text-field>
						<p v-if="status_remark.amount > requestedAmount" class="orange--text mt-1">
							You are approving more than requested claim amount.
						</p>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col lg="3" cols="12" class="">
						<Label for="" class="fw-500 d-flex required"> Remark:</Label>
					</v-col>
					<v-col md="9" class="py-0">
						<TextAreaInput
							:rows="4"
							dense
							:word-limit="200"
							hide-details
							placeholder="Enter remark..."
							v-model="status_remark.remark"
							:rules="[vrules.required(status_remark.remark, 'Remark')]"
							:class="{
								required: !status_remark.remark,
							}"
						></TextAreaInput>
					</v-col>
				</v-row>

				<v-row class="mb-3">
					<v-col cols="3" class="">
						<div class="">
							<label class="fw-500">Attachment</label>
						</div>
					</v-col>
					<v-col cols="9" class="">
						<template v-for="(file, index) in files">
							<div class="mb-2" :key="index">
								<v-row>
									<v-col cols="9">
										<span class="w-30 pr-3" style="overflow: hidden">
											<v-file-input
												:id="`document-file-${index}`"
												placeholder="Select File"
												outlined
												class=""
												prepend-icon=""
												prepend-inner-icon="mdi-attachment"
												hide-details
												v-model="file.file"
												v-on:change="updateFile(index, $event)"
												v-on:click:clear="updateFile(index, $event)"
											></v-file-input>
										</span>
										<span class="w-20 pr-3">
											<TextInput
												hideTopMargin
												:id="`document-name-${index}`"
												v-model="file.name"
												hide-details
												placeholder="File Name"
												:suffix="file.suffix"
											></TextInput>
										</span>
									</v-col>
									<v-col cols="3">
										<span class="w-10 pr-3">
											<v-btn
												:disabled="files.length < 2"
												v-on:click="removeFile(index)"
												color="red lighten-1 white--text"
												class=""
												tile
												depressed
												small
												outlined
												min-width="30"
												width="30"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</span>
										<span class="w-10 pr-3">
											<v-btn
												color="blue darken-4 white--text"
												class=""
												tile
												depressed
												small
												min-width="30"
												width="30"
												@click="addMore"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</span>
									</v-col>
								</v-row>
							</div>
						</template>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template #action>
			<v-row class="delete-dialog">
				<v-col md="12" class="py-0 my-auto">
					<p class="btx-p m-0 text-align-center" style="text-align: center">
						<span
							class="red--text lighten-1 font-level-1-bold"
							style="font-size: 19px !important; color: red !important"
							>Are you sure, You want to
							<span style="color: #24326d"
								><template v-if="status_remark.drop_status == 'Approved'">Approve</template
								><template v-if="status_remark.drop_status == 'Rejected'">Reject</template></span
							>
							this Claim
						</span>
					</p>
				</v-col>
			</v-row>
			<template v-if="status_remark.drop_status == 'Approved'">
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="green"
					tile
					@click="onSubmit"
				>
					Approve
				</v-btn>
			</template>
			<template v-if="status_remark.drop_status == 'Rejected'">
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="red lighten-1"
					tile
					@click="onSubmit"
				>
					Reject
				</v-btn>
			</template>
			<v-btn depressed tile :disabled="pageLoading" @click="closeDialog"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import { POST, QUERY } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { filter, toString } from "lodash";
import TextInput from "@/view/components/TextInput";
export default {
	name: "ClaimApprovalDialog",
	data() {
		return {
			claimDialog: false,
			formValid: true,
			pageLoading: false,
			claimSingle: {},
			status_remark: {
				drop_status: null,
				remark: null,
				drop_value: 0,
				amount: 0,
			},
			files: [
				{
					file: null,
					name: null,
				},
			],
			requestedAmount: 0,
			summaryData: {},
		};
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		actionType: {
			type: [String, Number],
			default: null,
		},
		claimId: {
			type: [String, Number],
			default: null,
		},
		claimData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		dialog: {
			handler(param) {
				this.claimDialog = param;
				if (param) {
					this.init();
				}
			},
			immediate: true,
		},
	},
	methods: {
		limitDecimal($event, value) {
			let string = toString(value);
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;
			//console.log(string,keyCode);
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				$event.preventDefault();
			}

			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files?.length < 1) {
				this.addMore();
			}
		},
		closeDialog() {
			this.$emit("close", true);
		},
		onSubmit() {
			const formErrors = this.validateForm(this.$refs["claimApprovalForm"]);
			if (formErrors.length) {
				if (!this.status_remark.remark || this.status_remark.remark == "null") {
					this.$store.commit(SET_ERROR, [{ model: true, message: `Remark is required!` }]);
					return false;
				}

				//this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				//return false;
			}

			let formData = new FormData();
			formData.append("amount", this.status_remark.amount ? this.status_remark.amount : 0);
			formData.append(
				"drop_status",
				this.status_remark.drop_status ? this.status_remark.drop_status : null
			);
			formData.append("drop_value", this.status_remark.drop_value ? this.status_remark.drop_value : 0);
			formData.append("remark", this.status_remark.remark ? this.status_remark.remark : "");
			for (let i = 0; i < this.files?.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}

			if (this.status_remark.drop_status == "Approved") {
				if (this.status_remark.amount > this.summaryData[0].balance) {
					const _error = [
						{
							message: "Amount is not available",
							model: true,
							timeout: 2000,
						},
					];
					this.$store.commit(SET_ERROR, _error);
					return false;
				}
			}
			if (this.status_remark.drop_status == "Rejected") {
				this.status_remark.amount = this.requestedAmount;
			}

			// for (let i = 0; i < this.files?.length; i++) {
			// 	if (this.files && this.files[i] && this.files[i].file) {
			// 		formData.append(`file[${i}][file]`, this.files[i].file);
			// 		formData.append(`file[${i}][name]`, this.files[i].name);
			// 	}
			// }

			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `status-remark-claim/${this.claimId}`, data: formData }) //this.status_remark
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success! Status updated successfully." },
					]);
					this.status_remark = {};

					this.$refs.claimApprovalForm.reset();
					this.$emit("close", false);
					this.$emit("success", data);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			this.getCategoryOptions();
			if (this.actionType == 2) {
				this.status_remark.drop_status = "Approved";
				this.status_remark.drop_value = 2;
			} else if (this.actionType == 3) {
				this.status_remark.drop_status = "Rejected";
				this.status_remark.drop_value = 3;
			}
			this.claimSingle = this.claimData;
			this.requestedAmount = Number(this.claimSingle.amount).toFixed(2);
			this.status_remark.amount = this.requestedAmount;
		},
		getCategoryOptions() {
			const { user, expense_date, claim_month } = this.claimData;

			const _this = this;
			_this.$store
				.dispatch(QUERY, {
					url: "setting/claim/option/" + user,
					data: { expense_date, claim_month },
				})
				.then((data) => {
					const expenseList = data.claim_categories;
					this.summaryData = filter(expenseList, (row) => {
						return row.category == _this.claimData.expense_category;
					});
				})
				.catch((error) => {
					_this.logError(error);
				});
		},
		getStatusColor(status) {
			if (status == "Approved") {
				return "green  white--text";
			} else {
				return "red white--text";
			}
		},
	},
	components: {
		Dialog,
		TextAreaInput,
		TextInput,
	},
};
</script>
